import React, { useEffect, useState,useRef } from "react";

import Calculate from "components/layouts/payment/Calculator";
import Formmoneypay from "components/layouts/payment/Inputmoneypay";
import { nanoid } from 'nanoid'

export default function  PayQr({
        PaymentDetailObj,
        modalSuccessOpenObj,
        providerObj,
        Payment,
        eObj,
        moneypayObj,
        deliveryObj,
        btnObj,...props
    }) {
        // console.log("🚀 ~ eObj:", eObj)
        // console.log("🚀 ~ Payment:", Payment)
        // console.log("🚀 ~ providerObj:", providerObj)
        // console.log("🚀 ~ PaymentDetailObj:", PaymentDetailObj)

    const [step, setStep] = useState(1); 
    const [PaymentQr, setPaymentQr] = useState(null); 
    const [time, setTime] = useState(0);
    const [count, setCount] = useState(0);

    const [payID, setPayID] = useState(nanoid());


    const getQr = (amount) => {
        const item = {
            "id":payID,
            "amount":amount,
            "qrImage":'/img/image_qr.png',
            "status":0
        }
        setPaymentQr(item)
        
        setStep(2);
        handleAddPayment();
    };

    const handleAddPayment = () => {

        if(deliveryObj.deliverySelect){
            var item = {
              "id":  payID ,
              "channel":{"paymentChannelID":4,"paymentChannelName":"Delivery"},
              "subchannel":Payment.PaymentSelect,
              "amount":moneypayObj.moneypay,
              "provider":providerObj.provider,
              "remark":null,
              "status":  0
            }
        }else{
            var item = {
              "id":  payID ,
              "channel":Payment.PaymentSelect,
              "subchannel":null,
              "amount":moneypayObj.moneypay,
              "provider":providerObj.provider,
              "remark":null,
              "status":  0
            }
        }

        Payment.setPaymentObj( (prev) => [ ...prev,item ])
        moneypayObj.setMoneypay('0.00')
        providerObj.setProvider(null)
    }


    const getPaymentStatus = () => {
        let id = PaymentQr.id
        if(count == 0){
            setCount(1)
            setTime(5)
        }else{
            
            let status = 'success';
            if(status == 'success'){
                Payment.setPaymentObj( (prev) => 
                    prev.map((el) =>
                        el.id === id
                        ? {
                            ...el,
                            status: el.status = 1
                            }
                        : el
                    ) 
                )
                setPaymentQr(null)
                setStep(3);
            }else if(status == 'fail'){
                Payment.setPaymentObj( (prev) => 
                    prev.map((el) =>
                        el.id === id
                        ? {
                            ...el,
                            status: el.status = 2
                            }
                        : el
                    ) 
                )
                setPaymentQr(null)
                setStep(3);
            }else{
                setCount(0)
            }
        }

    }
    useEffect(() => {
        if (time > 0) {
            setTimeout(() => setTime(time - 1), 1000);
        } else {
            if(PaymentQr){
                getPaymentStatus()
            }
        }
    })

    useEffect(() => {
        if(Payment?.PaymentSelect?.id) {
            setPaymentQr(prev => ({
                ...prev,
                qrImage: `https://api.alldercafe.com/${Payment.PaymentSelect.qr_image_url}`
            }))
        }
    }, [Payment])
    


    return (
        <>
     
            { step == 1 ? (
            <PaymentCreate 
                moneypayObj={moneypayObj}
                deliveryObj={deliveryObj}
                eObj={eObj}
                PaymentDetailObj={PaymentDetailObj}
                btnObj={btnObj}
                modalSuccessOpenObj={modalSuccessOpenObj}
                handleClick={() => { getQr(moneypayObj.moneypay); } }
                Payment={Payment}
            />
            ) : ''
            }

            { step == 2 ? (
            <QrCodeView 
                PaymentQr={PaymentQr}
            />
            ) : ''
            }

            { step == 3 ? (
            <PaymentSuccess 
                eObj={eObj}
                btnObj={btnObj}
                id={payID}
                Payment={Payment}
            />
            ) : ''
            }

        </>
    )

}

const PaymentCreate = ({moneypayObj,eObj,PaymentDetailObj,btnObj,modalSuccessOpenObj,handleClick,deliveryObj,Payment,...props}) => {
    return (
        <>
            <div>
                {
                    deliveryObj.deliverySelect ? (
                        <button 
                            type="button" 
                            className="fs-12 mb-2" 
                            style={{color:'#0760FC'}}
                            onClick={() => { 
                                deliveryObj.setDeliverySelect(null)
                                Payment.setPaymentObj([])
                            }}
                        >{`<<`} ล้างรายการ</button>
                    ) : ''
                }
            </div>
            <div className="d-flex align-items-center text-[#061126] mb-1">
                <div className="me-auto">
                <span className="fs-18 fw-500">Order NO.</span>
                <span className="fs-14 fw-500 ms-2">{PaymentDetailObj.PaymentDetail.orderNo}</span>
                </div>
                <div className="fs-18 fw-bold text-[#2DC76D]">สร้าง QR ชำระเงิน</div>
            </div>
            {
                deliveryObj.deliverySelect ? (
                    <div className="fs-14 fw-500 mb-3 d-flex align-items-center"> 
                        <span className="me-2"> กรุณาระบุยอดเงินรับชำระของ </span> 
                        <img src={deliveryObj.deliverySelect.logo} style={{width: '26px',height: '26px',objectFit:'cover',borderRadius:'5px'}} />
                    </div>
                ) : (
                    <div className="fs-14 fw-500 mb-3">กรุณาระบุยอดเงินรับชำระ</div>
                )
            }
            

            <div className="py-2">
                <Formmoneypay 
                moneypay={moneypayObj.moneypay}
                setMoneypay={moneypayObj.setMoneypay}
                />
            </div>

            <Calculate 
                setMoneypay={moneypayObj.setMoneypay}
                moneypay={moneypayObj.moneypay}
                PaymentDetail={PaymentDetailObj.PaymentDetail}
            />

            <div className="d-flex align-items-center pt-2">
                <button className="me-auto btn btn-st-q1 px-5"
                onClick={handleClick}
                disabled={btnObj.BtnenterDisabled || Number(moneypayObj.moneypay) == 0}
                >Enter
                </button>
                <button className="btn btn-st-q1"
                onClick={eObj.handlePayment}
                disabled={btnObj.BtnpaymentDisabled}
                >ชำระเงิน</button>
            </div>
        </>
    )
}

const QrCodeView = ({PaymentQr}) => {

    return (
        <div className="pt-5 px-4">
            <div className="text-center fs-24 fw-500 text-[#061126] mb-2">080-888-8762</div>
            <div className="text-center fs-24 fw-500 text-[#061126]">โรงคั่วกาแฟพะเยา</div>
            <div className="text-center my-2">
                <img src={PaymentQr ? PaymentQr.qrImage : '' } className="w-100 mx-auto" style={{maxWidth:"338px"}} />
            </div>
            <div className="d-flex align-items-center">
                <div className="me-auto fs-24 fw-500 text-[#061126]">ยอดที่ต้องชำระ</div>
                <div className="text-nowrap d-flex align-items-center">
                    <span className="fs-36 fw-bold text-[#2DC76D] me-4 ">{PaymentQr ? PaymentQr.amount : '0.00' }</span>
                    <span className="fs-18 fw-500 text-[#2DC76D] pt-2">THB</span>
                </div>
            </div>

            <div className="text-center my-3">
                <button className="btn btn-x1 btn-x1-print text-[#FFF] " style={{paddingLeft:'40px'}}
                >พิมพ์ QR code</button>
            </div>
        </div> 
    )
}

const PaymentSuccess = ({Payment,id,eObj,btnObj}) => {

    const pay = Payment.PaymentObj.filter(
        (p) => p.id == id
    );

    return (
        <div className="pt-5 px-4 h-100 d-flex flex-column justify-content-center">
            <div className="text-center mb-4 pt-5 mt-5">
                <img className="w-[min(8.11vw,83px)] aspect-square object-contain rounded-full mx-auto" src="/img/icons/icon-success.png" alt="" />
            </div>
            <div className="text-center fs-36 fw-500 mx-auto text-[#2DC76D] mt-4 mb-5">การชำระเงินเสร็จสิ้น</div>
            <div className="d-flex align-items-center mb-auto">
                <div className="me-auto fs-24 fw-500 text-[#061126]">จำนวนที่ชำระ</div>
                <div className="text-nowrap d-flex align-items-center">
                    <span className="fs-36 fw-bold text-[#2DC76D] me-4 ">{pay.length > 0 ? pay[0].amount : '0.00' }</span>
                    <span className="fs-18 fw-500 text-[#2DC76D] pt-2">THB</span>
                </div>
            </div>
            <div className="text-end">
                <button className="btn btn-st-q1"
                    onClick={eObj.handlePayment}
                    disabled={btnObj.BtnpaymentDisabled}
                >ชำระเงิน</button>
            </div>
        </div>
    )
} 